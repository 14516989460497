exports.components = {
  "component---src-pages-brands-index-tsx": () => import("./../../../src/pages/brands/index.tsx" /* webpackChunkName: "component---src-pages-brands-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thank-you-index-tsx": () => import("./../../../src/pages/contact/thank-you/index.tsx" /* webpackChunkName: "component---src-pages-contact-thank-you-index-tsx" */),
  "component---src-pages-cookies-index-tsx": () => import("./../../../src/pages/cookies/index.tsx" /* webpackChunkName: "component---src-pages-cookies-index-tsx" */),
  "component---src-pages-ethical-commitment-index-tsx": () => import("./../../../src/pages/ethicalCommitment/index.tsx" /* webpackChunkName: "component---src-pages-ethical-commitment-index-tsx" */),
  "component---src-pages-global-reach-index-tsx": () => import("./../../../src/pages/globalReach/index.tsx" /* webpackChunkName: "component---src-pages-global-reach-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-how-we-give-index-tsx": () => import("./../../../src/pages/howWeGive/index.tsx" /* webpackChunkName: "component---src-pages-how-we-give-index-tsx" */),
  "component---src-pages-life-at-ulac-index-tsx": () => import("./../../../src/pages/lifeAtUlac/index.tsx" /* webpackChunkName: "component---src-pages-life-at-ulac-index-tsx" */),
  "component---src-pages-milestones-index-tsx": () => import("./../../../src/pages/milestones/index.tsx" /* webpackChunkName: "component---src-pages-milestones-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-sustainability-index-tsx": () => import("./../../../src/pages/sustainability/index.tsx" /* webpackChunkName: "component---src-pages-sustainability-index-tsx" */),
  "component---src-pages-terms-of-use-index-tsx": () => import("./../../../src/pages/termsOfUse/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-tsx" */),
  "component---src-pages-who-we-are-index-tsx": () => import("./../../../src/pages/whoWeAre/index.tsx" /* webpackChunkName: "component---src-pages-who-we-are-index-tsx" */)
}

